@import "global.css";

.authLeftSide {
    background-color: var(--primary-clr);
    background-image: url("../assets/images/overlayImg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: inherit;
    top: 0;
    left: 0;
}

.ant-picker-dropdown {
    z-index: 100000;
  }
  @media only screen and (min-width: 992px) and (max-width: 1280px)  {
  span.tool{
    margin-top: 0 !important;
  }
}
.hideClose .btn-close {
  display: none;
}