@media screen and (max-height: 600px) {
    ul.sidemenu {
        height: 58% !important;
    }
    ul.bottomUl {
        height: 32%;
    }
    .swiper, .swiper-wrapper {
        min-height: 250px;
        overflow: hidden;
      }

}

@media screen and (max-width: 1920px) {
    .pdfViewer {
        height: 680px;
        overflow-y: scroll !important;
    }
}
@media screen and (max-width: 1600px) {
    .terms a {
        font-size: var(--font-20);
    }
    .swiper-wrapper h2 {
        font-size: 48px !important;
    }
    .pdfViewer {
        height: 680px;
        overflow-y: scroll !important;
    }
    .reportModuleTable .ant-table-content {
        overflow-x: scroll;
        width: 100%;
    }
    .reportModuleTable .ant-table-content table {
        width: 1500px;
        overflow-x: scroll;
        z-index: 0;
        position: relative;
    }
    .removeScroll {
        width: 100% !important;
    }
}
@media screen and (max-width: 1440px) {
    .timeline-heading h5 {
        font-size: 13px;
    }
    .pdfViewer {
        height: 650px;
        overflow-y: scroll !important;
    }
    .sidebarMain.userSidebar {
        height: 570px;
    }

}
@media screen and (max-width: 1400px) {
    .manualWork .manualWorkBoxArrow:after {
        width: 80%;
        height: 25px;
        left: 105%;
    }
}
@media screen and (max-width: 1366px) {
/* .swiper-pagination {
    bottom: -30px !important;
  } */
  .dateSelect {
    font-size: 13px !important;
    }
    ul.sidemenu {
        height: 60% !important;
        width: 100%;
    }
    .sidebarMain.userSidebar {
        height: 530px;
    }
}
@media screen and (max-width: 1280px) {
    .addBtn {
        padding: 10px 18px;
    }
    .reportSearch {
        margin-bottom: 3px !important;
    }
    .hScreen {
        height: 24px !important;
        font-size: 12px !important;
    }
    /* h2 {
        font-size: 21px !important;
    } */
    .swiper-wrapper h2 {
        font-size: var(--font-38) !important;
    }
    ul.headerIcons {
        margin-left: 8px !important;
    }
    ul.headerIcons li {
        margin-right: 5px !important;
        padding: 10px 12px;
    }
    .form-switch {
        padding-left: 1.5em;
    }
    button.socialLogin span {
        font-size: 15px;
    }
    .leftbar ul.sidemenu li {
        padding: 8px 15px !important;
    }
    button.reject, button.approve {
        padding: 5px 10px !important;
        margin-right: 5px !important;
    }
    .dashboardBox h5 {
        font-size: 32px !important;
    }
    .dashboardBoxIcon img {
        width: 30px;
    }
    .dashboardBoxIcon {
        padding: 15px !important;
    }
    td.ant-table-cell {
        padding: 8px 16px !important;
    }
    .leftbar ul.sidemenu img {
        width: 15px;
      }
      .leftbar ul.sidemenu span {
        font-size: 14px;
      }
      .leftbar ul.sidemenu li {
        padding: 5px 10px !important;
      }
      ul.contactList.contactListDashboard li {
        padding: 3px !important;
        font-size: 14px;
    }
    .reportSearch {
        margin-bottom: 5px !important;
    }
    .sidebarMain.userSidebar {
        height: 400px;
    }
}

@media screen and (max-width: 1199px) {
    .swiper, .swiper-wrapper {
        min-height: 340px !important;
      }
    .leftbar ul.sidemenu li {
        padding: 12px 15px !important;
    }
    .manualWork .manualWorkBoxArrow:after {
        width: 55%;
        height: 15px;
        left: 101%;
        top: 45%;
      }
      .manualWorkContent {
        min-height: 620px;
      }
      .manualWorkOne p {
        min-height: 100px;
        font-size: 14px;
        padding: 0 10px;
    }
}
@media screen and (max-width: 1024px) {
    .sidebarMain.userSidebar {
        height: 600px;
    }
}
@media screen and (max-width: 992px) {
    a.socialLogin {
        width: 100% !important;
        margin-bottom: 15px;
    }
    h2 {
        font-size: var(--font-h1-42) !important;
    }
    .rightbar {
        width: 100% !important;
    }
    .leftbar {
        display: none;
    }
    .navbar-expand-sm .navbar-toggler {
        display: block;
    }
    .navbar-expand-sm .navbar-collapse {
        display: none;
    }
    .responsiveMenu {
        right: 0;
        width: 370px;
        z-index: 99999;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
        top: 50px;
        height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .profileDropdown, .headerIcons {
        display: none !important;
    }
    .respoBar {
        display: block !important;
    }
    .manualWorkOne {
        width: 50%;
    }
    .manualWorkContent {
        min-height: auto;
        margin-bottom: 25px;
      }
      .manualWorkParent {
        flex-wrap: wrap;
        justify-content: center;
      }
      .manualWorkBox.manualWorkBoxArrow {
        margin-bottom: 20px;
    }
    /* .manualWorkOne .manualWorkBoxArrow:after {
        display: none;
    } */
    .manualWorkOne:nth-child(2) .manualWorkBoxArrow:after, .manualWorkOne:nth-child(4) .manualWorkBoxArrow:after, .manualWorkOne:nth-child(5) .manualWorkBoxArrow:after {
        display: none;
    }
    .manualWork .manualWorkBoxArrow:after {
        width: 170%;
        height: 25px;
        left: 140%;
        top: 45%;
    }
    .manualWorkOne p {
        min-height: 30px;
    }
}
@media screen and (max-width: 768px) {
.roleList .table-search {
    width: 100% !important;
    margin: 0 !important;
}
}
@media screen and (max-width: 767px) {
    .userDropdown {
        display: none !important;
    }
    .tool::after { 
        font-size: .75em;
        margin-left: -5em;
        width: 10em; 
  }
    .terms a {
        font-size: var(--font-12);
    }
    .authLeftSide {
        position: relative;
        height: auto;
    }
    .authFooter {
        text-align: center;
    }
    h2 {
        font-size: var(--font-28) !important;
    }
    /* .swiper-pagination {
        bottom: -10px !important;
    } */
    button.socialLogin {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
    }

    .ant-table-content table {
        width: 900px;
        overflow: scroll;
        z-index: 0;
        position: relative;
    }
    .ant-table-content {
        overflow-x: scroll;
        width: 100%;
    }
    .table-search input {
        margin-bottom: 10px;
    }
    ul.contactList.contactListDashboard li {
        width: 50%;
        text-align: center;
        padding: 10px !important;
    }
    .run-rate {
        width: 100%;
    }
    .monthlyDropdown {
        margin: 10px 0;
        border: 1px solid #dddddd !important;
    }
    .addBtn {
        width: 100%;
    }
    .table-search {
        width: 100% !important;
        margin: 10px 0;
    }
    ul.contactList li {
        width: 50%;
        text-align: center;
    }
    .notificationDropdown {
        width: 100%;
        top: 0;
    }
    .fc .fc-toolbar {
        display: block;
    }
    .fc-dayGridMonth-view.fc-view.fc-daygrid {
        width: 900px;
    }    
    .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
        width: 100%;
    }
    .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:nth-child(2) {
        margin: 10px 0;
    }
    .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:nth-child(1) {
        display: flex;
        justify-content: space-between;
    }
    .fc-view-harness.fc-view-harness-active {
        overflow-x: scroll;
        height: 500px !important;
    }
    .fc-day-past .fc-daygrid-day-top a, .fc-day-future .fc-daygrid-day-top a {
        font-size: 11px;
        width: 18px;
        height: 18px;
    }
    .accessPermission .form-check-input {
        width: 1em;
        height: 1em;
        margin-top: 4px !important;
    }
    .accessPermission label {
        font-size: 14px !important;
    }
    .roleInput {
        width: 100% !important;
    }

    .uploadIconBtn img {
        width: 25px;
    }

    /* Super admin mobile view css */
    .userDropdownInner {
        right: -30px;
        width: 320px;
    }
    .table-search {
        width: 100% !important;
        margin: 0 !important;
    }
    .fc-dayGridWeek-view.fc-view.fc-daygrid, .fc-dayGridWeek-view.fc-view.fc-daygrid table {
        width: 900px !important;
    }
    
    .fc-dayGridWeek-view.fc-view.fc-daygrid tbody {
        width: 100% !important;
    }
    span.notiCount {
        padding: 3px 6px;
        font-size: 12px;
    }
    section.formSection {
        margin-bottom: 50px !important;
    }
    .getStarted {
        box-shadow: none;
    }
    .getStarted-inner h2 {
        font-size: 21px !important;
        padding: 0 !important;
    }
    .invoiceAnalytics {
        width: 100%;
        overflow-x: scroll;
    }
    .graphContainer {
        width: 100%;
        overflow-x: scroll;
    }
    .ant-upload.ant-upload-drag {
        height: 65vh;
    }
    .fc-timeGridDay-view.fc-view.fc-timegrid {
        overflow-x: scroll;
        width: 500px !important;
    }
    .fc-timeGridDay-view.fc-view.fc-timegrid table {
        width: 500px !important;
    }
    .fc-timegrid-body {
        width: 500px !important;
    }
    .generalTimeline .approve:after, .generalTimeline .default:after, .generalTimeline .reject:after {
        bottom: -60px;
        height: 60px;
    }
    .table-search, .table-search.w-75 {
        width: 100% !important;
    }
    .srcCross button {
        top: 12px !important;
    }
    .alertDialog .MuiPaper-root.MuiPaper-elevation {
        width: 100%;
    }
    .AdminChartGraph {
        width: 700px;
    }
    ul.lists.mainUl {
        display: block;
    }
    .board h4 {
        text-align: left !important;
    }
    .twoFa button {
        width: 100% !important;
      }
      .twoFa input {
        width: 40px !important;
        height: 40px;
      }
      .twoFa img {
        width: 75% !important;
      }
      .pdfNav {
        z-index: 9;
        top: -2px;
    }
      .generalTimeline {
        font-size: 13px !important;
    }
    .tool::after, .tool::before {
        display: none;
    }
    thead.ant-table-thead {
        z-index: 0 !important;
        position: relative;
    }
    .swiper-wrapper h2 {
        font-size: var(--font-28) !important;
    }
    .swiper, .swiper-wrapper {
        min-height: auto !important;
        /* overflow: hidden; */
      }
      .userDropdown-option:hover {
        background-color: #ffffff;
    }
    .userDropdown-option:hover span {
        color: #333333;
    }
    .userDropdown-option:hover img {
        filter: contrast(0.5);
    }
    th.ant-table-cell {
        z-index: 0 !important;
    }
    .reportInvoice {
        width: 100%;
    }
    .ant-table-wrapper, .ant-spin-nested-loading, .ant-table, .ant-table-content {
        z-index: 0 !important;
    }
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
        display: block !important;
    }
    .ant-picker-dropdown {
        left: 0 !important;
        right: 0 !important;
    }
    .manualWorkOne {
        width: 100%;
    }
    .manualWorkOne:nth-child(2) .manualWorkBoxArrow:after, .manualWorkOne:nth-child(4) .manualWorkBoxArrow:after {
        display: block;
    }
    .manualWorkOne:nth-child(5) .manualWorkBoxArrow:after {
        display: none;
    }
    .manualWork .manualWorkBoxArrow:after {
        width: 50%;
        height: 25px;
        left: 20%;
        bottom: -38%;
        transform: rotate(90deg);
        top: auto;
    }
        .manualWorkOne p {
        min-height: auto;
    }
    .termsContainer h2 {
        font-size: 24px !important;
    }
    .table-search {
        width: 100% !important;
        margin: 0 !important;
    }
}

@media screen and (max-width: 360px) {
    .ant-upload.ant-upload-drag {
        height: 60vh;
    }
    .responsiveMenu {
        right: 0;
        width: 320px;
        z-index: 99999;
        box-shadow: 0 0 20px rgba(0,0,0,0.1)
    }
}