:root {
  --primary-clr: #0ac05e;
  --secondary-clr:#191a15;
  --text-secondary: #615E83;
  --btn-dark: #009b48;
  --search-bg:#F9F9F9;
  --primaryColor: rgba(2, 160, 252, 0.1);
  --primaryColorText: rgba(2, 160, 252, 1);
  --successColor: rgba(52, 181, 58, 0.1);
  --successColorText: rgba(52, 181, 58, 1);
  --warningColor: rgba(255, 178, 0, 0.1);
  --warningColorText: rgba(115, 87, 39, 1);
  --purpleColor: rgba(0, 119, 255, 0.11);
  --purpleColorText: rgba(0, 119, 255, 1);
  --dangerColorText: rgba(225, 66, 85, 0.2);
  --dangerColor: rgba(225, 66, 85, 1);
  --verifiedColor: rgba(255, 178, 0, 1);
  --verifiedColorText: rgba(255, 178, 0, 0.11);

  --grey:#f1f1f1;
  --bggrey: #FAFAFA;
  --white:#ffffff;
  --danger: #CC5F5F;
  --success: #519C66;
  --greyDark: #7D7E83;
  
  --font-wt300:300;
  --font-wt500:500;
  --font-wt700:700;
  --font-wt900:900;
  --font-wt1000:1000;
  --font-16:16px;
  --font-14:14px;
  --font-12:12px;
  --font-18:18px;
  --font-20:20px;
  --font-24:24px;
  --font-26:26px;
  --font-28:28px;
  --font-58:58px;
  --font-48:48px;
  --font-38:38px;
  --font-h1-42:42px;
  --font-h2-34:34px;
  --br-radius-6:6px;
  --br-radius-8:8px;
  }