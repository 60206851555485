.swiper {
    width: 600px;
    height: 300px;
    direction: 'vertical';
    /* loop: 'true'; */
}
.swiper .scrollbar {
    el: '.swiper-scrollbar'
}
.swiper .pagination {
    el: '.swiper-pagination'
}